import { createError, defineNuxtRouteMiddleware } from '#imports'

import { useFeatureFlags } from '@/composables/useFeatureFlag'

export default defineNuxtRouteMiddleware((to) => {
  const flags = useFeatureFlags()
  const requiredFlags = to.meta.flags?.required || []

  if (
    requiredFlags.every((flag) => {
      return flags.value[flag]
    })
  ) {
    return
  }

  return createError({ statusCode: 404 })
})
